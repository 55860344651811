/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'
import ButtonDefault from 'components/elements/ButtonDefault'
import AboutUs from 'components/shared/before-footer/AboutUs'
import ContactCTA from 'components/ContactCTA'
import VacatureDetailContent from 'components/elements/VacatureDetailContent'

// Third Party
import styled from 'styled-components'
// import ReactPlayer from 'react-player/lazy'

// Helpers
import { getLanguage } from 'services/language'

const StyledVacatureDetail = styled.div``

const DetailTitle = styled.h2`
  position: relative;
  left: -50px;
  font-size: ${props => props.theme.font.size.xl};
  width: 80%;

  @media screen and (max-width: 991px) {
    left: 0;
    width: 100%;
    font-size: ${props => props.theme.font.size.xl};
  }
`

const CTADiv = styled.div`
  right: 0;
`

const StyledContactCTA = styled(ContactCTA)`
position: absolute;
  left: 0;
  right: 0;
`

// const FormContainer = styled.div`
//   background-color: ${props => props.theme.color.grey};
// `

// const FormTitle = styled.h2`
//   color: ${props => props.theme.color.text.light};
// `

// const StyledPlayer = styled(ReactPlayer)`
//   position: relative;
//   background-color: #000000;
//   height: 100%;
//   width: 100%;
// `

// const PlayerContainer = styled.div`
//   width: 530px;
//   height: 300px;

//   position: relative;
//   right: -65px;

//   @media screen and (max-width: 991px) {
//     width: 100%;
//     min-height: 250px;
//     right: 0;
//   }
// `

const postTemplate = ({
  data: {
    page: { 
      seo,
      post
    },
  },
}) => {
  const language = getLanguage();

  return (
    <Layout withButton={false}>
      {seo && (
        <SEO
          seo={seo}
          image={post.postbanner.image.localFile.childImageSharp.meta.src}
        />
      )}

      <Hero src={post.postbanner.image} title={post.postbanner.title} />

      <StyledVacatureDetail className="container pt-3 pb-5">
        <div className="row position-relative">
          <div className="col-lg-9">
            <ButtonDefault to={`${language === 'nl_NL' ? '/nieuws' : '/en/news'}`} className="mb-5 mt-4 mt-lg-0">
              {language === 'nl_NL' ? (
                <span>&lt; Terug naar overzicht</span>
              ) : (
                <span>&lt; Back to overview</span>
              )}
              
            </ButtonDefault>
            <DetailTitle className="pb-5">{post.description.title}</DetailTitle>
            <VacatureDetailContent content={post.description.content} className="pl-lg-5" />
          </div>
          <CTADiv className="col-lg-6 d-none d-lg-block position-absolute">
            <StyledContactCTA content={post.contact} />
          </CTADiv>
        </div>
       
        <div className="col-lg-6 d-lg-none px-0 pt-5">
            <ContactCTA content={post.contact} />
            
          </div>
      </StyledVacatureDetail>
      <AboutUs />
    </Layout>
  )
}

export default postTemplate

export const pageQuery = graphql`
  query($id: String) {
    page: wpPost(id: { eq: $id }) {
      title
      uri
      post {
        postbanner {
          image {
            localFile {
              childImageSharp {
               gatsbyImageData(width: 2000, quality:100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
        }
        description {
          title
          content
        }
        contact {
          title
          link {
            title
            url
            target
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`